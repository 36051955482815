<template>
  <SolutionTagCloudBlock
    :headline="computedProps.headline"
    :text="computedProps.text"
    :tags="tags"
  />
</template>

<script setup lang="ts">
import { SolutionTagCloudBlock } from '@hypercodestudio/basler-components';
import type { TagInterface } from '@hypercodestudio/basler-components/dist/components/elements/taglist/TagList.vue';
import type { ITagCloudContainer } from '~/lib/ContentfulService';

interface Props {
  item: ITagCloudContainer;
}

const props = defineProps<Props>();

const tags = computed((): TagInterface[] => {
  if (!props.item.fields.tags) {
    return [];
  }

  const _tags: TagInterface[] = [];
  for (const tag of props.item.fields.tags) {
    const link = useBuildLinkInterface(props.item.fields.linkToTagOverviewPage);

    link.uri = link.uri + '?topic=' + tag.fields.slug;

    _tags.push({
      text: tag.fields.title,
      link
    });
  }

  return _tags;
});

const computedProps = computed(() => {
  return {
    headline: props.item.fields.headline,
    text: props.item.fields.text ?? '',
    tags: props.item.fields.tags ?? []
  };
});
</script>
